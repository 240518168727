import React, {useState} from 'react';
import {Card, Col, Input, List, Row, Space, Tooltip, Typography} from 'antd';
import NextcloudLogo from "./logo/nextcloud-logo-icon-blue.png"
import OmegaLogo from "./logo/omega_logo.png"
import ReportiveLogo from "./logo/reportive_logo.svg"
import SirtiLogo from "./logo/Logo_Sirti.png"
import SiteLogo from "./logo/logo_site.png"
import IntredLogo from "./logo/logo_intred.svg"
import ValtellinaLogo from "./logo/logo-Valtellina-SpA.png"
import SielteLogo from "./logo/sielte_logo.png"
import LambdaLogo from "./logo/lambda_black.svg"
import AhrwLogo from "./logo/ahrw_logo.png"
import SnipeITLogo from "./logo/snipeit.png"

const {Link, Title, Text} = Typography

const data = [
    {
        title: 'Snipe-IT',
        description: 'Gestione attrezzature',
        url: 'https://snipeit.gencarellisrl.it',
        img: SnipeITLogo
    },
    {
        title: 'Snipe-IT Consegna Beni',
        description: 'Consegna mobile attrezzature',
        img: SnipeITLogo,
        url: 'https://snipeit2.gencarellisrl.it'
    },
    {
        title: 'Reportive',
        description: 'Gestione commesse',
        url: 'https://reportive.gencarellisrl.it',
        img: ReportiveLogo
    },
    {
        title: 'Ad Hoc Revolution Web',
        description: 'Gestionale contabilità',
        url: 'https://ahrw.gencarellisrl.it/ahrw',
        img: AhrwLogo
    },
    {
        title: 'Omega HR',
        description: 'Gestione del personale e della sicurezza',
        url: 'https://omega.gencarellisrl.it',
        img: OmegaLogo
    },
    {
        title: 'Lambda',
        description: "Gestione veicoli ed attrezzature",
        url: 'https://lambda.gencarellisrl.it',
        img: LambdaLogo
    },
    {
        title: 'Nextcloud',
        description: 'Gestione e condivisione documenti',
        url: 'https://nextcloud.gencarellisrl.it/apps/files',
        img: NextcloudLogo
    },
    {
        title: 'Portale Sirti',
        description: 'Portale Sirti S.p.A.',
        url: 'https://extranet.portale.sirti.net',
        img: SirtiLogo
    },
    {
        title: 'Portale Sielte',
        description: 'Portale Sielte S.p.A.',
        url: 'https://portale.sielte.it',
        img: SielteLogo
    },
    {
        title: 'Portale Valtellina',
        description: 'Portale Valtellina S.p.A.',
        url: 'http://vp.valtellina.com:8080/agomireim/servlet/login',
        img: ValtellinaLogo
    },
    {
        title: 'Portale Site',
        description: 'Portale Site S.p.A.',
        url: 'https://site-fornitori.appspot.com/gestione/fornitori/home.jsp#qualifiche',
        img: SiteLogo
    },
    {
        title: 'Portale Intred',
        description: 'Portale Intred S.p.A.',
        url: 'https://areaimprese.intred.it/gm.php',
        img: IntredLogo
    }
];
const App = () => {
    const [actualData, setActualData] = useState(data)

    const onTextChange = e => {
        const filterText = e.target.value.toUpperCase() ?? "";
        setActualData(data.filter(x =>
            x.title.toUpperCase().indexOf(filterText) >= 0 ||
            x.description.toUpperCase().indexOf(filterText) >= 0
        ))
    }
    return <Row style={{margin: 16}}>
        <Col sm={{span: 20, offset: 2}} md={{span: 16, offset: 4}} lg={{span: 12, offset: 6}}>
            <Space direction={"vertical"} size={16} style={{width: '100%'}}>
                <Title level={2}>Collegamenti rapidi applicazioni</Title>
                <Row style={{marginTop: 20}}>
                    <Col lg={{span: 8, offset: 8}} md={{span: 16, offset: 4}} sm={{span: 14, offset: 5}} xs={{span: 14,offset: 5}}>
                        <Input placeholder={"Cerca"} allowClear onChange={onTextChange}/>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <List
                            grid={{
                                gutter: 16,
                                column: 4,
                                lg: 3,
                                md: 3,
                                sm: 3,
                                xs: 2
                            }}
                            dataSource={actualData}
                            renderItem={(item) => (
                                <List.Item>
                                    <Card style={{height: 150, overflow: "hidden"}} hoverable
                                          onClick={() => window.open(item.url, "_blank")}>
                                        <div style={{display: "flex", flexDirection: "column", rowGap: 15}}>
                                            <div style={{textAlign: "center"}}>
                                                <img
                                                    style={{
                                                        width: '50px',
                                                        height: '30px',
                                                        objectFit: 'contain'
                                                    }}
                                                    src={item.img}/>
                                            </div>
                                            <Space direction={"vertical"} size={5}>
                                                <Text strong ellipsis>{item.title}</Text>
                                                <Tooltip title={item.description}>
                                                    <Text type={"secondary"} ellipsis>{item.description}</Text>
                                                </Tooltip>
                                            </Space>
                                        </div>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </Space>
        </Col>
    </Row>
}
export default App;
